import 'owl.carousel';
import helper from './helpers';
function ScrollHeader() {
	var scrollTarget = $('#header'),
		scrollClass = 'fixed-scroll';
	if(helper.isElementScrolledTo(scrollTarget[0].id)) {
		scrollTarget.addClass(scrollClass);
	} else {
		scrollTarget.removeClass(scrollClass);
	}
}
function triggerLoginForm() {
	var $class = 'active',
		$target = $('#login-form'),
		$login = $('.triggerLoginForm');
	$login.on('click', function() {
		$target.addClass($class);
		if($target.hasClass($class)) {
			$target.find('.close').on('click', function() {
				$target.removeClass($class);
			});
		}
		$target.on('click', function(e) {
			if(e.target.id === 'login-form') {
				$target.removeClass($class);
			}
		});
	});
	if(helper.$_GET('login')) {
		setTimeout(function() {
			$login.trigger('click');
		});
	}
}
function enableCookies() {
	// set cookie policy
	if(!helper.getCookie('cookie-accept')) {
		var $html = '';
		$html += '<div id="cookie">';
			$html += '<div class="col-row">';
				$html += '<div class="col s12 m12 l10">';
					$html += 'We use cookies to improve your experience on our site and to show you relevant information and advertising. By continuing to use our website, you are agreeing to the <?php echo get_bloginfo(); ?> policy and privacy policy. For more information about the cookies we collect from you and information about our policies, visit our <a href="<?php echo get_site_url(); ?>/cookie-policy">cookie policy</a> and <a href="<?php echo get_site_url(); ?>/privacy-policy">privacy policy</a>.';
				$html += '</div>';
				$html += '<div class="col s12 m12 l2">';
					$html += '<button class="ui-btn">Accept and close</button>';
				$html += '</div>';
			$html += '</div>';
		$html += '</div>';
		$('#app').append($html);
	}
	$('#cookie button').on('click', function() {
		helper.setCookie('cookie-accept', true, 365);
		$('#cookie').remove();
	});
}
function activateNavMenu() {
	// mobile nav
	$('#nav-menu').on('click', function() {
		var app = $('#app'),
			activeClass = 'menu-active';
		app.addClass(activeClass);
		if(app.hasClass(activeClass)) {
			$('#nav-mask').click(function() {
				app.removeClass(activeClass);
			});
		}
	});
}
function enableUIToggle() {
	var toggle = $('.ui-toggle');
	toggle.each(function() {
		var load = $(this).data('toggle');
		if(load == 'show') {
			$(this).find('.ui-toggle-content').css("display", "block");
		}
	});
	$('.ui-toggle-title').on('click', function() {
		$(this).next('.ui-toggle-content').toggle();
	});
}
function doMobileNav() {
	var nav = $('#nav');
	if(window.innerWidth < 1100) {
		nav.find('.menu-item-has-children').hover(function() {
			$(this).children('.sub-menu-wrap').addClass('show');
		}, function() {
			$(this).children('.sub-menu-wrap').removeClass('show');
		});
	}
}
function activateTestimonialsSlider() {
	var owl = $('#testimonials-slider .slider');
	if(owl.children('.slide').length > 1) {
		owl.owlCarousel({
			loop: true,
			items: 1,
			autoHeight: true,
			autoplay: true,
			autoplayTimeout: 4000,
			autoplayHoverPause: true,
			dots: true,
			nav: false,
			navText: [
				'<span class="fa fa-chevron-left"></span>',
				'<span class="fa fa-chevron-right"></span>',
			]
		});
	}
}
function activateSliderCarousel() {
	var owl = $('.slider-carousel .slider');
	owl.owlCarousel({
		loop: true,
		items: 10,
		dots: false,
		autoplay: false,
		autoplayHoverPause: true,
		nav: true,
		navText: [
			'<span class="fa fa-chevron-left"></span>',
			'<span class="fa fa-chevron-right"></span>',
		],
		responsive: {
			0: {
				items: 4,
				autoplay: true,
			},
			600: {
				items: 5,
				autoplay: true,
			},
			960: {
				items: 10,
			},
		}
	});
}
// checkbox access
function doCheckboxAccess() {
	$('.ui-checkbox input').on('keypress', function(e) {
		e.preventDefault();
		if(e.keyCode === 13) {
			$(this).prop('checked', !$(this).prop('checked'));
		}
	});
}
jQuery(document).ready(function($) {
	// nav scroll
	ScrollHeader();
	// tabs
	helper.enableTabs();
	// trigger login form
	triggerLoginForm();
	// enable cookies
	enableCookies();
	// activate nav menu
	activateNavMenu();
	// do mobile nav
	doMobileNav();
	// activate testimonials slider
	activateTestimonialsSlider();
	// activate carousel slider
	activateSliderCarousel();
	// ui select
	helper.enableUISelect();
	// ui toggle
	enableUIToggle();
	// checkbox access
	doCheckboxAccess();
	// on scroll
	$(window).on('scroll', function(e) {
		// if scroll meets end of page, stop scrolling
		if($(window).scrollTop() + $(window).height() != $(document).height()) {
			ScrollHeader();
		}
	});
	// on resize
	$(window).on('resize', function() {
		doMobileNav();
	});
});