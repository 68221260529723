<template>
	<div id="newsletter">
		<form method="post" @submit.prevent="signUp">
			<div class="col-row col-flush">
				<div class="col l8 m8 s12">
					<div class="form-block">
						<label class="form-label">
							<div class="form-field input">
								<input type="email" v-model="email" class="field" maxlength="64" placeholder="Email address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
							</div>
						</label>
					</div>
				</div>
				<div class="col l4 m4 s12">
					<button type="submit" class="ui-btn ui-btn-block">Sign up</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				email: '',
				action: 'http://future-medicine-news.msgfocus.com/k/Future-Medicine/video_journal_of_biomedicine_enews_sign_up'
			}
		},
		methods: {
			signUp() {
				let self = this;
				$('#newsletter input').each(function() {
					if( !self.email ) {
						$(this).addClass('error');
					} else {
						var form = $('#newsletter form');
						form.attr('action', self.action);
						form.submit()[0];
					}
				});
			}
		}
	}	
</script>