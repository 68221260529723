import '../sass/sass.scss';
import './functions.js';
import ScriptX from 'vue-scriptx';

import {createApp} from 'vue';
const vue = createApp({});

vue.use(ScriptX);

vue.config.globalProperties.$app = app;

vue.component('login', require('./components/login').default);
vue.component('register', require('./components/register').default);
vue.component('forgot-password', require('./components/forgot-password').default);
vue.component('reset-password', require('./components/reset-password').default);
vue.component('newsletter', require('./components/newsletter').default);
vue.component('contact', require('./components/contact').default);
vue.component('user-profile', require('./components/user-profile').default);
vue.component('social-logins', require('./components/social-logins').default);

vue.mount('#app');