<template>
	<div id="register-form">
		<h1>Register <span>Step {{step+1}}</span></h1>
		<p>Stay up-to-date with the latest news, opinion, peer reviewed journal articles, and <br> get content tailored for you when you complete the full registration process.</p>
		<form @submit.prevent="signUp">
			<div class="step" v-for="step, index in steps" :key="`step-${index}`">
				<div class="col-row" v-if="isStepFirst">
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-firstname" class="form-label required">
								<span class="label">Firstname</span>
								<div class="form-field input">
									<input type="text" class="field" placeholder="Firstname">
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-surname" class="form-label required">
								<span class="label">Surname</span>
								<div class="form-field input">
									<input type="text" class="field" placeholder="Surname">
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-email" class="form-label required">
								<span class="label">Email address</span>
								<div class="form-field input">
									<input type="email" v-model="model.email" class="field" maxlength="64" placeholder="Email address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-password" class="form-label required">
								<span class="label">Password</span>
								<div class="form-field input">
									<input type="password" v-model="model.password" class="field" placeholder="Password" required>
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-job-title" class="form-label required">
								<span class="label">Job title</span>
								<div class="form-field input">
									<input type="text" class="field" placeholder="Job title">
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-job-function" class="form-label required">
								<span class="label">Job function</span>
								<div class="form-field select">
									<select class="field">
										<option value="" selected disabled>Please choose</option>
										<option value="1">1</option>
									</select>
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-company-name" class="form-label required">
								<span class="label">Company name</span>
								<div class="form-field input">
									<input type="text" class="field" placeholder="Company name">
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-sector" class="form-label required">
								<span class="label">Sector</span>
								<div class="form-field select">
									<select class="field">
										<option value="" selected disabled>Please choose</option>
										<option value="1">1</option>
									</select>
								</div>
							</label>
						</div>
					</div>
					<div class="col l6 m6 s12">
						<div class="form-block">
							<label id="your-country" class="form-label required">
								<span class="label">Country</span>
								<div class="form-field select">
									<select class="field">
										<option value="" selected disabled>Please choose</option>
										<option value="1">1</option>
									</select>
								</div>
							</label>
						</div>
					</div>
				</div>
				<div class="col-row" v-if="isStepEnd">
					<div class="col l12 m12 s12">
						<div class="form-block">
							<label id="your-job-function" class="form-label required">
								<span class="label">Select main speciality</span>
								<div class="form-field select">
									<select class="field">
										<option value="" selected disabled>Select main speciality</option>
										<option value="1">1</option>
									</select>
								</div>
							</label>
						</div>
					</div>
					<div class="col l12 m12 s12">
						<div class="form-block">
							<div class="form-label required">
								<span class="label">Select your interests</span>
								<p>Please select your areas of interest (you must check at least one):</p>
								<div class="col-row">
									<div class="col l4 m6 s6" v-for="i in 9">
										<div class="ui-checkbox">
											<label>
												<input type="checkbox" />
												<span></span>
												Lorem impsum is simply {{i}}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col l12 m12 s12">
						<div class="form-block">
							<div class="form-label">
								<span class="label">I wish to be contacted by {{app.name}} for:</span>
								<div class="col-row">
									<div class="col l6 m6 s6" v-for="i in 6">
										<div class="ui-checkbox">
											<label>
												<input type="checkbox" />
												<span></span>
												Lorem impsum is simply {{i}}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="buttons">
				<button v-if="isStepEnd" type="button" class="ui-btn ui-btn-outline back-btn" @click="goToStep('back')">Back a step</button>
				<button v-if="isStepFirst" type="button" class="ui-btn ui-btn-outline next-btn" @click="goToStep('next')">Continue</button>
				<button v-if="isStepEnd" type="submit" class="ui-btn" v-text="sending ? 'Signing you up...' : 'Register'" :disabled="sending"></button>
			</div>
			<div class="messages ui-text-center" v-if="messages" v-for="message in messages">
				<p v-text="message"></p>
			</div>
		</form>
		<!-- <social-logins /> -->
		<p>Already have an account? <a href="javascript:void();" class="triggerLoginForm">Login here</a></p>
	</div>
</template>
<script>
	import {userApi} from '../../api';
	export default {
		data () {
			return {
				app: app,
				step: 0,
				steps: 1,
				sending: false,
				messages: [],
				model: {}
			}
		},
		methods: {
			goToStep(step) {
				let self = this;
				if(step === 'back') {
					self.step -= 1;
				}
				if(step === 'next') {
					self.step += 1;
				}
				window.scroll(0, 0);
			},
			signUp() {
				let self = this;
				self.sending = true;
				self.messages = [];
				let signUpModel = {
					email: self.model.email,
					password: btoa(self.model.password)
				}
				userApi.register(signUpModel).then(response => {
					self.messages = response.data.message;
					self.initRegisterModel();
					self.sending = false;
				}).catch(error => {
					alert('Something went wrong. Please try again later');
					console.log(error);
					self.sending = false;
				});
			},
			initRegisterModel() {
				let self = this;
				self.model = {
					email: '',
					password: '',
				}
			},
			initMounted() {
				let self = this;
				self.initRegisterModel();
			}
		},
		computed: {
			isStepFirst() {
				let self = this;
				return self.step === 0;
			},
			isStepEnd() {
				let self = this;
				return self.step === self.steps;
			}
		},
		mounted () {
			let self = this;
			self.initMounted();
		}
	}
</script>