<template>
	<div class="social-logins">
		<a href="#" class="facebook"><span class="fab fa-facebook-f"></span> Login with Facebook</a>
		<a href="#" class="twitter"><span class="fab fa-twitter"></span> Login with Twitter</a>
		<a :href="$app.api_url + '/linkedin/auth'" class="linkedin"><span class="fab fa-linkedin-in"></span> Login with LinkedIn</a>
	</div>
</template>
<script>
	export default {
		
	}
</script>