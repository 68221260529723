<template>
	<div id="contact-form">
		<form @submit.prevent="processContact">
			<div class="col-row">
				<div class="col l6 m6 s12">
					<div class="form-block">
						<label id="your-firstname" class="form-label required">
							<div class="form-field input">
								<input type="text" v-model="model.firstname" placeholder="Firstname *" class="field">
							</div>
						</label>
					</div>
				</div>
				<div class="col l6 m6 s12">
					<div class="form-block">
						<label id="your-surname" class="form-label required">
							<div class="form-field input">
								<input type="text" v-model="model.surname" placeholder="Surname *" class="field">
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col l6 m6 s12">
					<div class="form-block">
						<label id="your-email" class="form-label required">
							<div class="form-field input">
								<input type="email" v-model="model.email" class="field" maxlength="64" placeholder="Email *" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
							</div>
						</label>
					</div>
				</div>
				<div class="col l6 m6 s12">
					<div class="form-block">
						<label id="your-subject" class="form-label">
							<div class="form-field input">
								<input type="text" v-model="model.subject" placeholder="Subject" class="field">
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="form-block">
				<label id="your-message" class="form-label required">
					<div class="form-field textarea">
						<textarea class="field" v-model="model.message" placeholder="Message *"></textarea>
					</div>
				</label>
			</div>
			<input class="g-recaptcha-response" type="hidden" />
			<div class="submit">
				<button type="submit" class="ui-btn ui-btn-outline" v-text="sending ? 'Sending...' : 'Submit'" :disabled="sending"></button>
			</div>
		</form>
	</div>
</template>
<script>
	import helper from '../../helpers';
	import {contactApi, recaptchaApi} from '../../api';
	export default {
		data () {
			return {
				sending: false,
				model: {}
			}
		},
		methods: {
			contact() {
				let self = this;
				self.sending = true;
				$('span.error').remove();
				contactApi.contact(self.model).then(response => {
					self.sending = false;
					let res = response.data;
					if(!res.error) {
						alert(res.message);
						self.initModel();
					}
				}, (error) => {
					self.sending = false;
					let res = error.response.data;
					if(res.error) {
						if(res.hasOwnProperty("errors")) {
							res.errors.forEach((e) => {
								$('#' + e.key).append('<span class="error">' + e.error + '</span>');
							});
						}
						if(res.message) {
							alert(res.message);
						}
					} else {
						alert("Something went wrong, please try again later");
					}
					console.log(error);
				});
			},
			processContact() {
				let self = this, token = $('.g-recaptcha-response').val();
				recaptchaApi.validateRecaptcha(token).then(res => {
					if(res.data.score >= 0.5) {
						self.contact();
					} else {
						alert("Invalid recaptcha, seems like your a robot");
					}
					// fetch new token
					helper.getRecaptchaToken();
				});
			},
			initModel() {
				let self = this;
				helper.getRecaptchaToken();
				self.model = {
					firstname: "",
					surname: "",
					subject: "",
					email: "",
					message: "",
				}
			},
			initMounted() {
				let self = this;
				self.initModel();
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>
