<template>
	<form @submit.prevent="save">
		<div class="form-tab">
			<div class="col-row auto-col-clear">
				<div class="col l6 m12 s12">
					<div class="form-block">
						<label id="your-firstname" class="form-label required">
							<span class="label">Firstname</span>
							<div class="form-field input">
								<input type="text" class="field" v-model="model.firstname" placeholder="Firstname">
							</div>
						</label>
					</div>
				</div>
				<div class="col l6 m12 s12">
					<div class="form-block">
						<label id="your-surname" class="form-label required">
							<span class="label">Surname</span>
							<div class="form-field input">
								<input type="text" class="field" v-model="model.lastname" placeholder="Surname">
							</div>
						</label>
					</div>
				</div>
				<div class="col l6 m12 s12">
					<div class="form-block">
						<label id="your-email" class="form-label required">
							<span class="label">Email</span>
							<div class="form-field input">
								<input type="email" class="field" maxlength="64" placeholder="Email address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$" v-model="model.email">
							</div>
						</label>
					</div>
				</div>
				<div class="col l6 m12 s12">
					<div class="form-block">
						<label id="your-password" class="form-label">
							<span class="label">Password</span>
							<div class="form-field input">
								<input type="password" class="field" v-model="model.password" placeholder="Change password here">
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
		<button type="submit" class="ui-btn ui-btn-outline" :disabled="sending" v-text="sending ? 'Updating...' : 'Save'"></button>
	</form>
</template>
<script>
	import {userApi} from '../../api';
	export default {
		props: {
			user: {
				required: true,
				type: String
			}
		},
		data() {
			return {
				sending: false,
				model: {}
			}
		},
		methods: {
			save() {
				let self = this;
				self.sending = true;
				$('.error').remove();
				userApi.update(self.model).then(response => {
					self.sending = false;
					if(!response.data.error) {
						alert("Account updated");
						self.model.password = "";
					}
				}, (error) => {
					let errors = error.response.data;
					console.log(errors);
					errors.validation_errors.forEach((error) => {
						$('#' + error.key).append('<span class="error">' + error.message + '</span>');
					});
					self.sending = false;
				});
			}
		},
		mounted() {
			let self = this;
			$('#your-password').on('mouseleave', function() {
				delete self.model["password"];
			});
			self.model = JSON.parse(self.user);
		}
	}
</script>